import React from 'react'
import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import { usePromise } from 'react-use'
import ProfileSettingsForm, { SubmitHandlerData } from '../../components/ProfileSettingsForm'
import { FIRESTORE } from '../../constants'
import { useAppTitle, useLoading, useProgressLoader, useUser } from '../../hooks'
import DefaultLayout from '../../layouts/default'
import { firebase } from '../../services'
import { UserProfile } from '../../types'

const ProfileSettings: React.FC = () => {
  const isMounted = usePromise()
  const { t } = useTranslation()
  const { userProfile, uid } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const { loading, setLoading, setValue, setError } = useLoading<void>()

  function handleOnSubmit (data: SubmitHandlerData) {
    if (!uid) {
      throw new Error('Could not create profile, UID missing!')
    }

    const userProfile: UserProfile = {
      displayName: data.displayName,
      email: data.email,
      preferences: {
        amount: data.amount,
        currency: data.currency
      }
    }

    const query = firebase.firestore
      .collection(FIRESTORE.PROFILES)
      .withConverter(firebase.converters.userProfile)
      .doc(uid)
      .set(userProfile)

    setLoading(true)

    isMounted(query)
      .then(setValue)
      .catch(setError)
  }

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, startLoader, stopLoader])

  useAppTitle(t('profile_settings.page_title'))

  return (
    <DefaultLayout title={t('profile_settings.page_title')}>
      <Card body>
        <ProfileSettingsForm
          amount={userProfile?.preferences?.amount}
          currency={userProfile?.preferences?.currency}
          disabled={loading}
          displayName={userProfile?.displayName}
          email={userProfile?.email}
          onSubmit={handleOnSubmit}
        />
      </Card>
    </DefaultLayout>
  )
}

export default ProfileSettings
