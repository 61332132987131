import dayjs, { Dayjs } from 'dayjs'
import { Month } from '../types'
import { MONTHS, DAYS_OF_WEEK } from '../constants'

export const DEFAULT_MULTIPLIER: number = 5

export function getMinutesInDay (fromMinute = 0, multiplier = DEFAULT_MULTIPLIER): number[] {
  const result: number[] = []
  let minutes = +fromMinute

  for (let i = 0; minutes < 24 * 60; i++) {
    result.push(minutes)

    minutes = minutes + multiplier
  }

  return result
}

export function getDaysInMonth (month: Month, year: number): number[] {
  const result: number[] = []
  const monthIndex = month - 1
  const daysInMonth = dayjs()
    .year(year)
    .month(monthIndex)
    .daysInMonth()

  for (let i = 1; i <= daysInMonth; i++) {
    result.push(i)
  }

  return result
}

export function getMonthsInYear (): Month[] {
  return MONTHS.map((_, index) => index + 1 as Month)
}

/**
 * Subtract provided year to provided number. Returning with formYear in list
 */
export function getPastYears (subtract = 3, fromYear = dayjs().year()): number[] {
  const result: number[] = [fromYear]

  for (let i = 1; i <= subtract; i++) {
    result.push(fromYear - i)
  }

  return result
}

/**
 * Map minutes to ISOString duration e.g. 9 min to "PT9M"
 */
export function mapMinutesToDuration (minutes: number): string {
  return dayjs.duration(minutes, 'minute').toISOString()
}

/**
 * Map duration e.g. "PT9M" to 9 as number
 */
export function mapDurationToMinutes (duration: string): number {
  return dayjs.duration(duration).asMinutes()
}

/**
 * Map Date object to minute as number
 */
export function mapDateToMinutes (date: Date): number {
  return (date.getHours() * 60) + date.getMinutes()
}

/**
 * Get rounded minutes from date e.g. T00:32 will return 30 minutes
 */
export function getRoundedMinutesFromDate (date: Date, multiplier = DEFAULT_MULTIPLIER): number {
  const minutes = mapDateToMinutes(date)

  return Math.round(minutes / multiplier) * multiplier
}

/**
 * Map date to rounded time e.g. T12:30
 */
export function mapDateWithRoundedTime (date = new Date(), multiplier = DEFAULT_MULTIPLIER): Date {
  const dayjsDate = dayjs(date)
  const roundedMinutes = Math.floor(dayjsDate.minute() / multiplier) * multiplier

  return dayjsDate
    .set('minute', roundedMinutes)
    .set('second', 0)
    .toDate()
}

/**
 * Map minutes to human hour by parts
 */
export function mapMinuteToParts (fromMinute: number): { hour: number, minute: number } {
  return {
    hour: Math.floor(fromMinute / 60),
    minute: fromMinute % 60
  }
}

/**
 * Map seconds to human hour by parts
 */
export function mapSecondsToPart (fromSeconds: number): { hour: number, minute: number, second: number } {
  return {
    hour: Math.floor(fromSeconds / 3600),
    minute: Math.floor((fromSeconds % 3600) / 60),
    second: fromSeconds % 60
  }
}

/**
 * Map minute to Human hour e.g. "12:00"
 */
export function mapMinuteToHumanHour (fromMinute: number): string {
  const { hour, minute } = mapMinuteToParts(fromMinute)
  const formattedHour: string = hour < 10 ? `0${hour}` : String(hour)
  const formattedMinute: string = minute < 10 ? `0${minute}` : String(minute)

  return `${formattedHour}:${formattedMinute}`
}

/**
 * Return readable seconds e.g. 62 sec will return 00:01:02
 */
export function mapSecondsToHumanHour (fromSeconds: number): string {
  const { hour, minute, second } = mapSecondsToPart(fromSeconds)
  const formattedHour: string = hour < 10 ? `0${hour}` : String(hour)
  const formattedMinute: string = minute < 10 ? `0${minute}` : String(minute)
  const formattedSecond: string = second < 10 ? `0${second}` : String(second)

  return `${formattedHour}:${formattedMinute}:${formattedSecond}`
}

/**
 * Map Date object to human hour e.g. "12:00"
 */
export function mapDateToHumanHour (date: Date): string {
  const minutes = mapDateToMinutes(date)

  return mapMinuteToHumanHour(minutes)
}

/**
 * Get month name e.g "may".
 */
export function getMonthName (month: Month): string {
  const monthIndex = month - 1

  if (month < 0 || month > 12) {
    throw new Error('Wrong month number provided!')
  }

  return MONTHS[monthIndex]
}

/**
 * Get day of week name e.g "saturday".
 */
export function getDayOfWeekName (dayOfWeek: number): string {
  if (dayOfWeek < 0 || dayOfWeek > 6) {
    throw new Error('Wrong day of week provided!')
  }

  return DAYS_OF_WEEK[dayOfWeek]
}

/**
 * Get day of month e.g "05" or "10".
 */
export function getDayOfMonth (dayOfMonth: number): string {
  if (dayOfMonth < 0) {
    throw new Error('Wrong day of month provided!')
  }

  if (dayOfMonth < 10) {
    return `0${dayOfMonth}`
  }

  return String(dayOfMonth)
}

/**
 * Create dayjs instancje from date numbers
 */
export function dateAsDayjs (year: number, month: Month, day: number, minute: number): Dayjs {
  const date = dayjs(`${year}-${month}-${day}`).set('minute', minute)

  if (!date.isValid()) {
    throw new Error(`Invalid date! 'Y${year}M${month}D${day}M${minute}'`)
  }

  return date
}

/**
 * Get difference seconds from date to current time
 */
export function secondsPassedFromDate (date: Date, fromDate = new Date()): number {
  const currentDate = dayjs(fromDate)

  return dayjs(currentDate).diff(date, 'second')
}
