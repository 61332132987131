import React from 'react'
import './user-avatar.scss'

type UserAvatarProps = {
  displayName: string
  size?: number
}

function displayNameInitials (displayName: string): string {
  const names = displayName.split(' ')
  const namesCount = names.length
  let initials = names[0].substring(0, 1).toUpperCase()

  if (namesCount > 1) {
    initials += names[namesCount - 1].substring(0, 1).toUpperCase()
  }

  return initials
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  displayName,
  size = 42
}) => {
  const initials = displayNameInitials(displayName)
  const style: React.CSSProperties = {
    width: size,
    height: size,
    borderRadius: size
  }

  return (
    <div className='user-avatar' style={style}>
      <div>{initials}</div>
    </div>
  )
}

export default UserAvatar
