import React from 'react'
import { useUnmount } from 'react-use'
import { identities } from '../helpers'
import { ProgressLoaderContext } from '../providers'

type UseProgressLoaderHook = {
  startLoader: () => void
  stopLoader: () => void
}

export function useProgressLoader (): UseProgressLoaderHook {
  const context = React.useContext(ProgressLoaderContext)
  const id = React.useRef<string>(identities.generateID())
  const timerRef = React.useRef<NodeJS.Timer>()

  if (!context) {
    throw new Error('Couldn\'t found LoaderContext!')
  }

  const startLoader = React.useCallback(() => {
    context.addLoader(id.current)
  }, [context])

  const stopLoader = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => context.removeLoader(id.current), 500)
  }, [context])

  useUnmount(stopLoader)

  return { startLoader, stopLoader }
}
