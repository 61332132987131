export const JANUARY = 'january'
export const FEBRUARY = 'february'
export const MARCH = 'march'
export const APRIL = 'april'
export const MAY = 'may'
export const JUNE = 'june'
export const JULY = 'july'
export const AUGUST = 'august'
export const SEPTEMBER = 'september'
export const OCTOBER = 'october'
export const NOVEMBER = 'november'
export const DECEMBER = 'december'

export const MONTHS = [
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER
]
