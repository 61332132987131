import dayjs from 'dayjs'
import { Month } from '../../types'

type ReducerAction = 'increase' | 'decrease' | 'today'

type MonthProviderState = {
  month: Month
  year: number
}

function todayMonthState (): MonthProviderState {
  const todayMonth = dayjs().month() + 1 as Month
  const todayYear = dayjs().year()

  return {
    month: todayMonth,
    year: todayYear
  }
}

export const initialState : MonthProviderState = todayMonthState()

export function monthProviderReducer (state: MonthProviderState, action: ReducerAction): MonthProviderState {
  switch (action) {
    case 'decrease': return {
      month: (state.month === 1 ? 12 : state.month - 1) as Month,
      year: state.month === 1 ? state.year - 1 : state.year
    }

    case 'increase': return {
      month: (state.month === 12 ? 1 : state.month + 1) as Month,
      year: state.month === 12 ? state.year + 1 : state.year
    }

    case 'today': return {
      ...todayMonthState()
    }

    default:
      return state
  }
}
