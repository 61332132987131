import { timesheetConverter } from './timesheetConverter'
import { userProfileConverter } from './userProfileConverter'
import { workTimeConverter } from './workTimeConverter'
import { workTimerConverter } from './workTimerConverter'

export default {
  timesheet: timesheetConverter,
  userProfile: userProfileConverter,
  workTime: workTimeConverter,
  workTimer: workTimerConverter
}
