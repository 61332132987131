import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { useTranslation } from 'react-i18next'
import './navigation-bar.scss'
import UserAvatar from '../UserAvatar'
import { ROUTES } from '../../constants'

type NavigationBarProps= {
  displayName: string
  title: string
  onSignOut: () => void
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  displayName,
  title,
  onSignOut
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  function handleOnProfileSettingsClick () {
    history.push(ROUTES.PROFILE_SETTINGS)
  }

  function handleOnSummaryClick () {
    history.push(ROUTES.SUMMARY)
  }

  function isActive (route: string): boolean {
    return location.pathname === route
  }

  return (
    <div className='d-flex justify-content-between align-items-center navigation-bar'>
      <h5 className='display-5 m-0'>{title}</h5>
      <div className='d-flex align-items-center'>
        <Dropdown>
          <Dropdown.Toggle as='div' id='profile-toggle' className='profile-toggle'>
            <UserAvatar displayName={displayName} />
          </Dropdown.Toggle>

          <Dropdown.Menu alignRight>
            <Dropdown.Header>
              <span className='display-name'>{displayName}</span>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item active={isActive(ROUTES.SUMMARY)} onClick={handleOnSummaryClick}>
              {t('navigation_bar.summary_text')}
            </Dropdown.Item>
            <Dropdown.Item active={isActive(ROUTES.PROFILE_SETTINGS)} onClick={handleOnProfileSettingsClick}>
              {t('navigation_bar.profile_settings_text')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onSignOut}>{t('navigation_bar.sign_out_menu_item_text')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default NavigationBar
