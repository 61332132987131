import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import dayjs from 'dayjs'
import { dateTime } from '../../helpers'
import './day-of-month.scss'

type DayOfMonthProps = {
  className?: string
  date: Date
}

const DayOfMonth: React.FC<DayOfMonthProps> = ({
  className,
  date
}) => {
  const { t } = useTranslation()
  const dayjsDate = dayjs(date)
  const dayOfWeek = dateTime.getDayOfWeekName(dayjsDate.day())
  const dayOfMonth = dateTime.getDayOfMonth(dayjsDate.date())

  return (
    <div className={classnames('day-of-month', className)}>
      <span className='text-muted small'>{t(`day_of_week.short.${dayOfWeek}`)}</span>
      <span className='day font-weight-bold'>{dayOfMonth}</span>
    </div>
  )
}

export default DayOfMonth
