import { firestore } from 'firebase'
import { Currency, UserProfile } from '../../../types'

interface UserProfileDocumentData extends firestore.DocumentData {
  displayName: string
  email: string
  preferences: {
    amount: number
    currency: Currency
  }
}

export const userProfileConverter: firestore.FirestoreDataConverter<UserProfile> = {
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options) as UserProfileDocumentData

    return {
      displayName: data.displayName,
      email: data.email,
      preferences: {
        amount: data.preferences.amount,
        currency: data.preferences.currency
      },
      uid: snapshot.id
    }
  },
  toFirestore: (userProfile) => userProfile
}
