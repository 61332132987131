import { firestore } from 'firebase'
import { WorkTimer } from '../../../types'

interface WorkTimerDocumentData extends firestore.DocumentData {
  startDate: firestore.Timestamp | null
}

export const workTimerConverter: firestore.FirestoreDataConverter<WorkTimer> = {
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options) as WorkTimerDocumentData

    return {
      startDate: data.startDate?.toDate()
    }
  },
  toFirestore: workTimer => {
    return {
      startDate: workTimer.startDate || null
    }
  }
}
