import React from 'react'
import { UserProfile } from '../../types'

export interface UserContextValue {
  error?: any
  hasProfile: boolean,
  loading: boolean
  uid?: string
  userProfile?: UserProfile
}

export const UserContext = React.createContext<UserContextValue | undefined>(undefined)
