import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { dateTime } from '../../helpers'

type TimerProps = {
  className?: string
  disabled?: boolean
  startDate?: Date
  onStart: (date: Date) => void
  onStop: (date: Date) => void
}

const Timer: React.FC<TimerProps> = ({
  className,
  disabled,
  startDate,
  onStart,
  onStop
}) => {
  const { t } = useTranslation()
  const interval = React.useRef<NodeJS.Timer>()
  const [seconds, setSeconds] = React.useState(0)

  function handleOnStartStopClick () {
    const currentDate = new Date()

    if (startDate) {
      onStop(currentDate)
    } else {
      onStart(currentDate)
    }
  }

  React.useEffect(() => {
    interval.current && clearInterval(interval.current)

    if (startDate) {
      interval.current = setInterval(() => {
        setSeconds(dateTime.secondsPassedFromDate(startDate))
      }, 1000)
    } else if (!startDate && seconds > 0) {
      setSeconds(0)
    }

    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, [startDate, seconds])

  return (
    <div className={classnames('timer', className)}>
      <div>
        <p className='display-4'>{dateTime.mapSecondsToHumanHour(seconds)}</p>
      </div>
      <div>
        <Button
          disabled={disabled}
          variant={startDate ? 'outline-danger' : 'outline-primary'}
          onClick={handleOnStartStopClick}
        >
          <FontAwesomeIcon icon={startDate ? faStop : faPlay} />
          <span className='ml-2'>{startDate ? t('timer.stop') : t('timer.start')}</span>
        </Button>
      </div>
    </div>
  )
}

export default Timer
