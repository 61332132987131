export const SUNDAY = 'sunday'
export const MONDAY = 'monday'
export const TUESDAY = 'tuesday'
export const WEDNESDAY = 'wednesday'
export const THURSDAY = 'thursday'
export const FRIDAY = 'friday'
export const SATURDAY = 'saturday'

export const DAYS_OF_WEEK = [
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
]
