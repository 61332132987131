import Firebase from 'firebase/app'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { usePromise } from 'react-use'
import { ROUTES } from '../../constants'
import { useAppTitle, useLoading, useProgressLoader, useUser } from '../../hooks'
import TightLayout from '../../layouts/Tight'
import { firebase } from '../../services'

const SignIn: React.FC<RouteComponentProps> = ({
  history
}) => {
  const { t } = useTranslation()
  const isMounted = usePromise()
  const { uid } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const { loading, setLoading, setValue, setError } = useLoading<void>(false)

  async function signInWithGoogle () {
    const provider = new Firebase.auth.GoogleAuthProvider()

    await firebase.auth.signInWithPopup(provider)
  }

  function handleSignWithGoogleClick () {
    setLoading(true)

    isMounted(signInWithGoogle())
      .then(setValue)
      .catch(setError)
  }

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, startLoader, stopLoader])

  React.useEffect(() => {
    if (uid) {
      history.replace(ROUTES.SUMMARY)
    }
  }, [uid, history])

  useAppTitle(t('sign_in.page_title'))

  return (
    <TightLayout>
      <Card body className='text-center'>
        <h1 className='h3 mb-3 front-weight-normal'>
          {t('sign_in.heading')}
        </h1>

        <Button block disabled={loading} variant='primary' onClick={handleSignWithGoogleClick}>
          {t('sign_in.google_button_text')}
        </Button>
      </Card>
    </TightLayout>
  )
}

export default SignIn
