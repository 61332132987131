import converters from './converters'
import { analytics, auth, firestore } from './firebase'

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FIREBASE_EMULATOR_HOST) {
  firestore.settings({
    host: process.env.REACT_APP_FIREBASE_EMULATOR_HOST,
    ssl: false
  })
}

export default {
  analytics,
  auth,
  converters,
  firestore
}
