import React from 'react'
import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { usePromise } from 'react-use'
import ProfileSettingsForm, { SubmitHandlerData } from '../../components/ProfileSettingsForm'
import { FIRESTORE, ROUTES } from '../../constants'
import { useAppTitle, useLoading, useProgressLoader, useUser } from '../../hooks'
import TightLayout from '../../layouts/Tight'
import firebase from '../../services/Firebase'
import { UserProfile } from '../../types'

const CreateProfile: React.FC = () => {
  const history = useHistory()
  const isMounted = usePromise()
  const { t } = useTranslation()
  const { userProfile, uid, hasProfile } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const { loading, setLoading, setValue, setError } = useLoading<void>()

  const defaultDisplayName = firebase.auth.currentUser?.displayName || undefined
  const defaultEmail = firebase.auth.currentUser?.email || undefined

  function handleOnSubmit (data: SubmitHandlerData) {
    if (!uid) {
      throw new Error('Could not create profile, UID missing!')
    }

    const userProfile: UserProfile = {
      displayName: data.displayName,
      email: data.email,
      preferences: {
        amount: data.amount,
        currency: data.currency
      }
    }

    const query = firebase.firestore
      .collection(FIRESTORE.PROFILES)
      .withConverter(firebase.converters.userProfile)
      .doc(uid)
      .set(userProfile)

    setLoading(true)

    isMounted(query)
      .then(setValue)
      .catch(setError)
  }

  React.useEffect(() => {
    if (hasProfile) {
      history.push(ROUTES.SUMMARY)
    }
  }, [hasProfile, history])

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, startLoader, stopLoader])

  useAppTitle(t('create_profile.page_title'))

  return (
    <TightLayout>
      <Card body>
        <h1 className='h3 mb-3 front-weight-normal text-center mb-5'>
          {t('create_profile.heading')}
        </h1>

        <ProfileSettingsForm
          disabled={loading}
          amount={userProfile?.preferences?.amount}
          currency={userProfile?.preferences?.currency}
          displayName={userProfile?.displayName || defaultDisplayName}
          email={userProfile?.email || defaultEmail}
          onSubmit={handleOnSubmit}
        />
      </Card>
    </TightLayout>
  )
}

export default CreateProfile
