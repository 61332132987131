import { UserProfile } from '../../types'

type UserProfileAction<U = UserProfile> = {
  type: 'user-profile'
  userProfile?: U
}

type AuthorizationAction = {
  type: 'authorize',
  uid: string
}

type UnauthorizeAction = {
  type: 'unauthorize'
}

type ErrorAction = {
  type: 'error',
  error: any
}

type ReducerAction = AuthorizationAction | UserProfileAction | UnauthorizeAction | ErrorAction

type UserProviderState<U = UserProfile, E = any> = {
  error?: E
  loading: boolean,
  uid?: string
  userProfile?: U,
}

export const initialState: UserProviderState = {
  loading: true
}

export function userProviderReducer (state: UserProviderState, action: ReducerAction): UserProviderState {
  switch (action.type) {
    case 'authorize': return {
      error: undefined,
      loading: state.loading,
      uid: action.uid,
      userProfile: undefined
    }

    case 'error': return {
      error: action.error,
      loading: false,
      uid: undefined,
      userProfile: undefined
    }

    case 'user-profile': return {
      error: undefined,
      loading: false,
      uid: state.uid,
      userProfile: action.userProfile
    }

    case 'unauthorize': return {
      error: undefined,
      loading: false,
      uid: undefined,
      userProfile: undefined
    }

    default:
      return state
  }
}
