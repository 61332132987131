import React from 'react'
import { firestore } from 'firebase'
import { useLoading } from './useLoading'

export type UseGetDocumentHook<T, E = any> = {
  error?: E
  loading: boolean
  value?: T
  setQuery: (query: firestore.DocumentReference<T>) => void
}

export function useGetDocument<T extends firestore.DocumentData> (
  query?: firestore.DocumentReference<T>
): UseGetDocumentHook<T> {
  const { error, loading, value, setLoading, setError, setValue } = useLoading<T>(true)
  const [queryRef, setQueryRef] = React.useState<firestore.DocumentReference<T> | undefined>(query)

  const setQuery = React.useCallback((newQuery: firestore.DocumentReference<T>) => {
    if (!queryRef?.isEqual(newQuery)) {
      setQueryRef(newQuery)
    }
  }, [queryRef, setQueryRef])

  React.useEffect(() => {
    if (queryRef) {
      queryRef.get()
        .then(snap => setValue(snap.data()))
        .catch(setError)

      setLoading(true)

      return () => {
        setLoading(false)
      }
    }
  }, [queryRef, setValue, setLoading, setError])

  return {
    error,
    loading,
    value,
    setQuery
  }
}
