import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import NavigationBar from '../../components/NavigationBar'
import { useUser } from '../../hooks'
import { firebase } from '../../services'

type DefaultLayoutProps = {
  title: string
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  title
}) => {
  const { userProfile } = useUser()

  async function handleOnSignOut () {
    await firebase.auth.signOut()
  }

  return (
    <Container as='main' className='pb-4'>
      <Row className='justify-content-center'>
        <Col md='10' sm='12'>
          <NavigationBar
            title={title}
            displayName={userProfile?.displayName || 'U'}
            onSignOut={handleOnSignOut}
          />
        </Col>
      </Row>

      <Row className='justify-content-center mt-4'>
        <Col md='10' sm='12'>{children}</Col>
      </Row>
    </Container>
  )
}

export default DefaultLayout
