import React from 'react'
import { Month } from '../../types'

export interface MonthContextValue {
  selected: {
    month: Month
    year: number
  }
  setNextMonth: () => void
  setPreviousMonth: () => void
  setTodayMonth: () => void
}

export const MonthContext = React.createContext<MonthContextValue | undefined>(undefined)
