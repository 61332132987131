import { Currency } from '../types'

/**
 * Map string value like '100,10' to float e.g. 100.1
 */
export function mapStringNumberToFloat (value: string): number {
  const cleanup = value.trim().replace(' ', '').replace(',', '.')
  const result = parseFloat(cleanup)

  if (isNaN(result)) {
    throw new Error(`Provided "${value}" value is not a number!`)
  }

  return result
}

/**
 * Map string value like '100,10' to amount e.g. 10010
 */
export function mapStringToAmount (value: string): number {
  const result = mapStringNumberToFloat(value)

  return result * 100
}

/**
 * Map amount value like 10010 to float e.g. 100.1
 */
export function mapAmountToFloat (value: number): number {
  return value / 100
}

/**
 * Map amount to Intl currency format e.g. "10 000,00 PLN"
 */
export function mapAmountToCurrencyFormat (amount: number, currency: Currency, locale?: string): string {
  const float = mapAmountToFloat(amount)
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency'
  })

  return formatter.format(float).replace(/\s+/g, ' ')
}
