import React from 'react'
import './progress-bar-loader.scss'

const ProgressBarLoader: React.FC = () => (
  <div className='progress-bar-loader'>
    <div className='bar'> </div>
  </div>
)

export default ProgressBarLoader
