import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { firestore } from 'firebase/app'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { usePromise } from 'react-use'
import WorkTimeEditor, { SubmitHandlerData } from '../../components/WorkTimeEditor'
import { FIRESTORE } from '../../constants'
import { dateTime, identities } from '../../helpers'
import { useAppTitle, useLoading, useProgressLoader, useUser } from '../../hooks'
import TightLayout from '../../layouts/Tight'
import { firebase } from '../../services'

const CreateWorkTime: React.FC<RouteComponentProps> = ({
  history
}) => {
  const isMounted = usePromise()
  const { t } = useTranslation()
  const { uid } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const { loading, setLoading, setError } = useLoading<void>()

  const handleOnMoveBackClick = () => {
    history.goBack()
  }

  const handleOnWorkTimeSubmit = async (data: SubmitHandlerData) => {
    if (!uid) {
      throw new Error('Could not add work time, UID missing!')
    }

    const batch = firebase.firestore.batch()
    const incrementMinutes = firestore.FieldValue.increment(data.durationAsMinutes)
    const timesheetDocID = identities.timesheetID(uid, data.year, data.month)

    const timesheetDocRef = firebase.firestore
      .collection(FIRESTORE.TIMESHEETS)
      .withConverter(firebase.converters.timesheet)
      .doc(timesheetDocID)

    const workTimesDocRef = firebase.firestore
      .collection(`${FIRESTORE.TIMESHEETS}/${timesheetDocID}/${FIRESTORE.WORK_TIMES}`)
      .withConverter(firebase.converters.workTime)
      .doc()

    setLoading(true)

    batch.set(timesheetDocRef, {
      totalMinutes: incrementMinutes as any
    }, { merge: true })

    batch.set(workTimesDocRef, {
      duration: dateTime.mapMinutesToDuration(data.durationAsMinutes),
      endDate: data.endDate,
      id: '',
      month: data.month,
      startDate: data.startDate,
      title: data.title,
      uid,
      year: data.year
    })

    isMounted(batch.commit())
      .then(() => history.goBack())
      .catch(setError)
  }

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, startLoader, stopLoader])

  useAppTitle(t('create_work_time.heading'))

  return (
    <TightLayout>
      <Card>
        <Card.Header>
          <Button variant='link' className='pl-0' onClick={handleOnMoveBackClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className='ml-2'>{t('create_work_time.move_back_text')}</span>
          </Button>
        </Card.Header>
        <Card.Body>
          <h1 className='h3 mb-3 font-weight-normal text-center'>
            {t('create_work_time.heading')}
          </h1>
          <WorkTimeEditor
            disabled={loading}
            onCancel={handleOnMoveBackClick}
            onSubmit={handleOnWorkTimeSubmit}
          />
        </Card.Body>
      </Card>
    </TightLayout>
  )
}

export default CreateWorkTime
