import React from 'react'
import Card from 'react-bootstrap/Card'

type SummaryCardProps = {
  className?: string
  title: string
  value: string
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  className,
  title,
  value
}) => {
  return (
    <Card body className={className}>
      <p className='text-muted mb-1 text-truncate'>{title}</p>
      <p className='mb-0 h4 font-weight-bold text-truncate'>{value}</p>
    </Card>
  )
}

export default SummaryCard
