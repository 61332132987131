import React from 'react'
import Button from 'react-bootstrap/Button'

type HeadingProps = {
  title: string
  actionTitle?: string
  onActionClick?: () => void
}

const Heading: React.FC<HeadingProps> = ({
  actionTitle,
  title,
  onActionClick
}) => {
  const handleOnActionClick = React.useCallback(() => {
    onActionClick && onActionClick()
  }, [onActionClick])

  return (
    <div className='d-flex justify-content-between align-items-center my-3'>
      <h5 className='mb-0 text-truncate'>{title}</h5>
      <Button variant='link' className='pr-0 font-weight-bold' onClick={handleOnActionClick}>
        {actionTitle}
      </Button>
    </div>
  )
}

export default Heading
