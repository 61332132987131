import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Card from 'react-bootstrap/Card'
import { useTranslation } from 'react-i18next'
import WorkTimeItem from '../../components/WorkTimeItem'
import { FIRESTORE } from '../../constants'
import { identities } from '../../helpers'
import { useCollection, useMonth, useProgressLoader, useUser } from '../../hooks'
import { firebase } from '../../services'
import { Month, WorkTime } from '../../types'

type WorkTimeListProps = {
  onItemClick?: (year: number, month: Month, id: string) => void
}

const WorkTimeList: React.FC<WorkTimeListProps> = ({
  onItemClick
}) => {
  const { t } = useTranslation()
  const { uid } = useUser()
  const { selected } = useMonth()
  const { startLoader, stopLoader } = useProgressLoader()
  const { collection, loading, setQuery } = useCollection<WorkTime>()

  const handleOnItemClick = React.useCallback((year: number, month: Month, id: string) => {
    if (onItemClick) {
      return () => onItemClick(year, month, id)
    }
  }, [onItemClick])

  React.useEffect(() => {
    if (uid) {
      const timesheetDocID = identities.timesheetID(uid, selected.year, selected.month)

      setQuery(firebase.firestore
        .collection(FIRESTORE.TIMESHEETS)
        .doc(timesheetDocID)
        .collection(FIRESTORE.WORK_TIMES)
        .orderBy('startDate', 'desc')
        .withConverter(firebase.converters.workTime))
    }
  }, [uid, selected, setQuery])

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, startLoader, stopLoader])

  if (!collection.length) {
    return (
      <Card body>
        <div className='p-5 text-center text-muted'>
          <FontAwesomeIcon className='mb-2' icon={faStopwatch} size='3x' />
          <p className='mb-0'>{t('summary.work_time_list.empty_text')}</p>
        </div>
      </Card>
    )
  }

  return (
    <Card body>
      {collection.map(workTime => (
        <WorkTimeItem
          key={workTime.id}
          duration={workTime.duration}
          endDate={workTime.endDate}
          startDate={workTime.startDate}
          title={workTime.title}
          onClick={handleOnItemClick(workTime.year, workTime.month, workTime.id)}
        />
      ))}
    </Card>
  )
}

export default WorkTimeList
