import React from 'react'
import { createPortal } from 'react-dom'
import ProgressBarLoader from '../../components/ProgressBarLoader'
import { ProgressLoaderContext, ProgressLoaderContextValue } from './ProgressLoaderContext'

const LoaderContextProvider: React.FC = ({
  children
}) => {
  const [loaders, setLoaders] = React.useState<string[]>([])
  const isLoading = !!loaders.length

  const addLoader = React.useCallback((id: string) => {
    if (loaders.indexOf(id) === -1) {
      setLoaders(state => [...state, id])
    }
  }, [loaders, setLoaders])

  const removeLoader = React.useCallback((id: string) => {
    if (loaders.indexOf(id) !== -1) {
      setLoaders(state => state.filter(loader => loader !== id))
    }
  }, [loaders, setLoaders])

  const value: ProgressLoaderContextValue = {
    addLoader,
    removeLoader
  }

  return (
    <ProgressLoaderContext.Provider value={value}>
      {isLoading && createPortal(
        <div className='loader-center'>
          <ProgressBarLoader />
        </div>,
        document.getElementById('root-loader')!
      )}
      {children}
    </ProgressLoaderContext.Provider>
  )
}

export default LoaderContextProvider
