import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { dateTime } from '../../helpers'
import { Month } from '../../types'

type MonthPickerProps = {
  disabled?: boolean,
  className?: string
  month: Month
  year: number
  onPreviousMonth: () => void
  onTodayMonth: () => void
  onNextMonth: () => void
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  disabled,
  className,
  month,
  year,
  onNextMonth,
  onPreviousMonth,
  onTodayMonth
}) => {
  const { t } = useTranslation()

  return (
    <div className={classnames('d-flex justify-content-between align-items-center', className)}>
      <h4 className='font-weight-bold mb-0'>
        {t(`month_picker.month_heading.${dateTime.getMonthName(month)}`, { year })}
      </h4>
      <div className='text-nowrap'>
        <Button disabled={disabled} variant='link' onClick={onPreviousMonth}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button disabled={disabled} variant='link' onClick={onTodayMonth}>
          <FontAwesomeIcon icon={faCircle} />
        </Button>
        <Button disabled={disabled} variant='link' onClick={onNextMonth}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </div>
  )
}

export default MonthPicker
