import React from 'react'

export type UseLoadingHook<V, E> = {
  error?: E
  loading: boolean
  value?: V
  setLoading: (loading?: boolean) => void
  setError: (error: E) => void
  setValue: (value?: V | undefined) => void
}

type FailedAction<E> = { type: 'failed', error: E }
type LoadingAction = { type: 'loading', loading: boolean }
type SuccessAction<V> = { type: 'success', value?: V }

type ReducerAction<V, E> = FailedAction<E> | LoadingAction | SuccessAction<V>

type ReducerState<V, E> = {
  error?: E
  loading: boolean
  value?: V
}

const getInitState = <V, E>(loading = false): ReducerState<V, E> => {
  return { loading }
}

const reducer = <V, E>() => (state: ReducerState<V, E>, action: ReducerAction<V, E>): ReducerState<V, E> => {
  switch (action.type) {
    case 'failed': return {
      loading: false,
      error: action.error,
      value: undefined
    }

    case 'loading': return {
      loading: action.loading,
      value: state.value
    }

    case 'success': return {
      loading: false,
      error: undefined,
      value: action.value
    }

    default:
      return state
  }
}

export function useLoading<V = any, E = any> (loading = false): UseLoadingHook<V, E> {
  const [state, dispatch] = React.useReducer(reducer<V, E>(), getInitState<V, E>(loading))

  const setLoading = React.useCallback((loading = true) => {
    dispatch({ type: 'loading', loading })
  }, [dispatch])

  const setError = React.useCallback((error: E) => {
    dispatch({ type: 'failed', error })
  }, [dispatch])

  const setValue = React.useCallback((value?: V) => {
    dispatch({ type: 'success', value })
  }, [dispatch])

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development' && state.error) {
      console.error(state.error)
    }
  }, [state.error])

  return {
    error: state.error,
    loading: state.loading,
    value: state.value,
    setLoading,
    setError,
    setValue
  }
}
