import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { firestore } from 'firebase/app'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps, useParams } from 'react-router-dom'
import { usePromise } from 'react-use'
import WorkTimeEditor, { SubmitHandlerData } from '../../components/WorkTimeEditor'
import { FIRESTORE, ROUTES } from '../../constants'
import { dateTime, identities } from '../../helpers'
import { useAppTitle, useGetDocument, useLoading, useProgressLoader, useUser } from '../../hooks'
import TightLayout from '../../layouts/Tight'
import { firebase } from '../../services'
import { WorkTime } from '../../types'

type RouteParams = {
  id: string
  month: string
  year: string
}

const EditWorkTime: React.FC<RouteComponentProps> = ({
  history
}) => {
  const isMounted = usePromise()
  const { t } = useTranslation()
  const { id, month, year } = useParams<RouteParams>()
  const { uid } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const { loading: updating, setLoading: setUpdating, setError: setUpdateError } = useLoading<void>()
  const { loading: loadingDocument, value, setQuery } = useGetDocument<WorkTime>()
  const [confirmModal, setConfirmModal] = React.useState(false)

  const disableEditor = loadingDocument || updating
  const handleOnConfirmModalClose = () => setConfirmModal(false)
  const handleOnConfirmModalOpen = () => setConfirmModal(true)
  const handleOnMoveBack = () => history.goBack()

  function handleOnDeleteClick () {
    if (!uid || !value) {
      throw new Error('Could not remove work time!')
    }

    const batch = firebase.firestore.batch()
    const timesheetDocID = identities.timesheetID(uid, value.year, value.month)
    const durationAsMinutes = dateTime.mapDurationToMinutes(value.duration)
    const incrementByMinutes = firestore.FieldValue.increment(-durationAsMinutes)

    const timesheetDocRef = firebase.firestore
      .collection(FIRESTORE.TIMESHEETS)
      .withConverter(firebase.converters.timesheet)
      .doc(timesheetDocID)

    const workTimeDocRef = firebase.firestore
      .collection(`${FIRESTORE.TIMESHEETS}/${timesheetDocID}/${FIRESTORE.WORK_TIMES}`)
      .withConverter(firebase.converters.workTime)
      .doc(value.id)

    batch.set(timesheetDocRef, {
      totalMinutes: incrementByMinutes as any
    }, { merge: true })

    batch.delete(workTimeDocRef)

    setUpdating(true)

    isMounted(batch.commit())
      .then(() => history.goBack())
      .catch(setUpdateError)
  }

  function handleOnWorkTimeSubmit (data: SubmitHandlerData) {
    if (!uid || !value) {
      throw new Error('Could update work time!')
    }

    const batch = firebase.firestore.batch()
    const timesheetDocID = identities.timesheetID(uid, value.year, value.month)
    const beforeDurationAsMinutes = dateTime.mapDurationToMinutes(value.duration)

    const workTimeDocRef = firebase.firestore
      .collection(`${FIRESTORE.TIMESHEETS}/${timesheetDocID}/${FIRESTORE.WORK_TIMES}`)
      .withConverter(firebase.converters.workTime)
      .doc(value.id)

    batch.update(workTimeDocRef, {
      duration: dateTime.mapMinutesToDuration(data.durationAsMinutes),
      endDate: data.endDate,
      startDate: data.startDate,
      title: data.title
    })

    if (beforeDurationAsMinutes !== data.durationAsMinutes) {
      const incrementByMinutes = firestore.FieldValue.increment(data.durationAsMinutes - beforeDurationAsMinutes)
      const timesheetDocRef = firebase.firestore
        .collection(FIRESTORE.TIMESHEETS)
        .withConverter(firebase.converters.timesheet)
        .doc(timesheetDocID)

      batch.update(timesheetDocRef, {
        totalMinutes: incrementByMinutes as any
      })
    }

    setUpdating(true)

    isMounted(batch.commit())
      .then(() => history.goBack())
      .catch(setUpdateError)
  }

  React.useEffect(() => {
    if (uid) {
      const timesheetDocID = identities.timesheetID(uid, year, month)

      setQuery(firebase.firestore
        .collection(FIRESTORE.TIMESHEETS)
        .doc(timesheetDocID)
        .collection(FIRESTORE.WORK_TIMES)
        .withConverter(firebase.converters.workTime)
        .doc(id)
      )
    }
  }, [uid, month, year, id, setQuery])

  React.useEffect(() => {
    (loadingDocument || updating) ? startLoader() : stopLoader()
  }, [loadingDocument, updating, startLoader, stopLoader])

  useAppTitle(t('edit_work_time.heading'))

  // TODO: Redirect not-found document to not-found page
  if (!loadingDocument && !value) {
    return <Redirect to={ROUTES.SUMMARY} />
  }

  if (!value) {
    return null
  }

  return (
    <TightLayout>
      <Card>
        <Card.Header>
          <Button variant='link' className='pl-0' onClick={handleOnMoveBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className='ml-2'>{t('edit_work_time.move_back_text')}</span>
          </Button>
        </Card.Header>

        <Card.Body>
          <h1 className='h3 mb-3 font-weight-normal text-center'>
            {t('edit_work_time.heading')}
          </h1>
          <WorkTimeEditor
            hideDate
            disabled={disableEditor}
            fromDate={value.startDate}
            title={value.title}
            toDate={value.endDate}
            onCancel={handleOnMoveBack}
            onRemove={handleOnConfirmModalOpen}
            onSubmit={handleOnWorkTimeSubmit}
          />
        </Card.Body>
      </Card>

      <Modal centered show={confirmModal} backdrop='static' keyboard={false} onHide={handleOnConfirmModalClose}>
        <Modal.Header closeButton={false}>
          <Modal.Title>{t('edit_work_time.confirmation_dialog.header_text')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {t('edit_work_time.confirmation_dialog.body_text', { title: value.title })}
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={disableEditor} variant='outline-secondary' onClick={handleOnConfirmModalClose}>
            {t('edit_work_time.confirmation_dialog.cancel_text')}
          </Button>
          <Button disabled={disableEditor} variant='danger' onClick={handleOnDeleteClick}>
            {t('edit_work_time.confirmation_dialog.confirm_text')}
          </Button>
        </Modal.Footer>
      </Modal>

    </TightLayout>
  )
}

export default EditWorkTime
