import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { useUser } from '../../hooks'

type RestrictedRouteProps = RouteProps & {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  component: Component,
  ...restProps
}) => {
  const { uid, error } = useUser()

  const render = (props: RouteComponentProps) => {
    if (!uid || error) {
      return <Redirect to={{ pathname: ROUTES.SIGN_IN, state: { from: props.location } }} />
    }

    return <Component {...props} />
  }

  return <Route render={render} {...restProps} />
}

export default RestrictedRoute
