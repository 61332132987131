import React from 'react'
import { UserContext, UserContextValue } from '../providers/User'

export function useUser (): UserContextValue {
  const context = React.useContext(UserContext)

  if (!context) {
    throw new Error('Couldn\'t found UserContext!')
  }

  return context
}
