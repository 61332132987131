import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import { Month } from '../../types'
import { dateTime } from '../../helpers'

type FormInputs = {
  dateDay: string
  dateMonth: string
  dateYear: string
  fromMinute: string
  title: string
  toMinute: string
}

export type SubmitHandlerData = {
  durationAsMinutes: number
  endDate: Date
  month: Month
  startDate: Date
  title: string
  year: number
}

type WorkTimeEditorProps = {
  disabled?: boolean
  fromDate?: Date
  hideDate?: boolean // TODO: Refactor WorkTimeEditor
  title?: string
  toDate?: Date
  onCancel?: () => void
  onRemove?: () => void
  onSubmit?: (data: SubmitHandlerData) => void
}

const WorkTimeEditor: React.FC<WorkTimeEditorProps> = ({
  disabled,
  fromDate,
  title,
  toDate,
  onCancel,
  onRemove,
  onSubmit
}) => {
  const { t } = useTranslation()
  const { errors, register, watch, handleSubmit } = useForm<FormInputs>()

  // Calendar defaults
  const defaultFromDate = dayjs(fromDate)
  const defaultDate = String(defaultFromDate.date())
  const defaultMonth = String(defaultFromDate.month() + 1)
  const defaultYear = String(defaultFromDate.year())
  const defaultFromMinute = String(fromDate ? dateTime.getRoundedMinutesFromDate(fromDate) : 420)
  const defaultToMinute = String(toDate ? dateTime.getRoundedMinutesFromDate(toDate) : undefined)

  // Current form state
  const currentMonth = Number(watch('dateMonth', defaultMonth)) as Month
  const currentYear = Number(watch('dateYear', defaultYear))
  const currentFromTime = Number(watch('fromMinute', defaultFromMinute))

  // Handlers
  const handleOnSubmit = (data: FormInputs) => {
    const dateYear = Number(data.dateYear)
    const dateMonth = Number(data.dateMonth) as Month
    const dateDay = Number(data.dateDay)
    const fromMinute = Number(data.fromMinute)
    const toMinute = Number(data.toMinute)
    const startDate = dateTime.dateAsDayjs(dateYear, dateMonth, dateDay, fromMinute)
    const endDate = dateTime.dateAsDayjs(dateYear, dateMonth, dateDay, toMinute)

    onSubmit && onSubmit({
      durationAsMinutes: endDate.diff(startDate, 'minute'),
      endDate: endDate.toDate(),
      month: dateMonth,
      startDate: startDate.toDate(),
      title: data.title,
      year: dateYear
    })
  }

  const handleOnCancelClick = () => {
    onCancel && onCancel()
  }

  return (
    <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
      <Form.Group controlId='title'>
        <Form.Control
          ref={register({ required: true, maxLength: 120 })}
          as='input'
          disabled={disabled}
          name='title'
          placeholder={t('work_time_editor.title.placeholder')}
          defaultValue={title}
          isInvalid={!!errors.title}
        />

        {errors.title && (
          <Form.Control.Feedback type='invalid'>
            {t(`work_time_editor.title.${errors.title.type}`)}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('work_time_editor.date_label')}</Form.Label>
        <Form.Row>
          <Col sm='3' className='mb-2 mb-sm-0'>
            <Form.Control
              custom
              ref={register}
              as='select'
              disabled={disabled}
              name='dateDay'
              defaultValue={defaultDate}
            >
              {dateTime.getDaysInMonth(currentMonth, +currentYear).map(day => (
                <option key={day} value={day}>{day}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm='6' className='mb-2 mb-sm-0'>
            <Form.Control
              custom
              ref={register}
              as='select'
              disabled={disabled || !!fromDate}
              name='dateMonth'
              defaultValue={defaultMonth}
            >
              {dateTime.getMonthsInYear().map(month => (
                <option key={month} value={month}>{t(`month.${dateTime.getMonthName(month)}`)}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm='3' className='mb-2 mb-sm-0'>
            <Form.Control
              custom
              ref={register}
              as='select'
              disabled={disabled || !!fromDate}
              name='dateYear'
              defaultValue={defaultYear}
            >
              {dateTime.getPastYears(5, Number(defaultYear)).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group as={Row} controlId='fromMinute'>
        <Form.Label column sm='6'>{t('work_time_editor.from_minute_label')}</Form.Label>
        <Col sm='6'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faClock} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              custom
              ref={register}
              as='select'
              disabled={disabled}
              name='fromMinute'
              defaultValue={defaultFromMinute}
            >
              {dateTime.getMinutesInDay().map(minute => (
                <option key={minute} value={minute}>
                  {dateTime.mapMinuteToHumanHour(minute)}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId='toMinute'>
        <Form.Label column sm='6'>{t('work_time_editor.to_minute_label')}</Form.Label>
        <Col sm='6'>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faClock} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              custom
              ref={register}
              as='select'
              disabled={disabled}
              name='toMinute'
              defaultValue={defaultToMinute}
            > {/* TODO: Think about toMinute offset handler */}
              {dateTime.getMinutesInDay(currentFromTime).map(minute => (
                <option key={minute} value={minute}>
                  {dateTime.mapMinuteToHumanHour(minute)}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>
      </Form.Group>

      <hr />

      <div className='d-flex justify-content-between'>
        <Button disabled={disabled} variant='link' onClick={handleOnCancelClick}>
          {t('work_time_editor.cancel_text')}
        </Button>
        <div>
          {onRemove && (
            <Button disabled={disabled} variant='outline-danger' className='mr-1' onClick={onRemove}>
              {t('work_time_editor.remove_text')}
            </Button>
          )}
          <Button disabled={disabled} type='submit'>
            {t('work_time_editor.submit_text')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default WorkTimeEditor
