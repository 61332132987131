import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { numbers } from '../../helpers'
import { Currency } from '../../types'

export type SubmitHandlerData = {
  amount: number
  currency: Currency
  displayName: string
  email: string
}

type FormInputs = {
  amount: string
  currency: Currency
  displayName: string
  email: string
}

type ProfileSettingsFormProps = {
  amount?: number
  currency?: Currency
  disabled?: boolean
  displayName?: string
  email?: string
  onSubmit: (user: SubmitHandlerData) => void
}

const ProfileSettingsForm: React.FC<ProfileSettingsFormProps> = ({
  amount,
  currency,
  disabled,
  displayName,
  email,
  onSubmit
}) => {
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm<FormInputs>()

  const defaultAmount = amount && numbers.mapAmountToFloat(amount)

  function handleOnSubmit (data: FormInputs) {
    onSubmit({
      amount: numbers.mapStringToAmount(data.amount),
      currency: data.currency,
      displayName: data.displayName,
      email: data.email
    })
  }

  return (
    <Form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
      <Form.Group controlId='displayName'>
        <Form.Label>{t('profile_settings_form.display_name.label')}</Form.Label>
        <Form.Control
          ref={register({ required: true })}
          disabled={disabled}
          defaultValue={displayName}
          name='displayName'
          type='text'
          placeholder={t('profile_settings_form.display_name.placeholder')}
          isInvalid={!!errors.displayName}
        />

        {errors.displayName && (
          <Form.Control.Feedback type='invalid'>
            {t(`profile_settings_form.display_name.${errors.displayName.type}`)}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId='email'>
        <Form.Label>{t('profile_settings_form.email.label')}</Form.Label>
        <Form.Control
          ref={register({ required: true, maxLength: 25 })}
          disabled={disabled}
          defaultValue={email}
          name='email'
          type='email'
          placeholder={t('profile_settings_form.email.placeholder')}
          isInvalid={!!errors.email}
        />

        {errors.email && (
          <Form.Control.Feedback type='invalid'>
            {t(`profile_settings_form.email.${errors.email.type}`)}
          </Form.Control.Feedback>
        )}
        <Form.Text className='text-muted'>{t('profile_settings_form.email.info')}</Form.Text>
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} sm={8} controlId='amount'>
          <Form.Label>{t('profile_settings_form.amount.label')}</Form.Label>
          <Form.Control
            ref={register({ required: true, pattern: /^\d*(\.\d{0,2})?$/ })}
            defaultValue={defaultAmount}
            disabled={disabled}
            isInvalid={!!errors.amount}
            min={0}
            name='amount'
            placeholder={t('profile_settings_form.amount.placeholder')}
            step={0.01}
            type='number'
          />

          {errors.amount && (
            <Form.Control.Feedback type='invalid'>
              {t(`profile_settings_form.amount.${errors.amount.type}`)}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group as={Col} sm={4} controlId='currency'>
          <Form.Label>{t('profile_settings_form.currency.label')}</Form.Label>
          <Form.Control
            custom
            as='select'
            ref={register({ required: true })}
            disabled={disabled}
            defaultValue={currency}
            name='currency'
          >
            <option value='PLN'>{t('profile_settings_form.currency.option.pln')}</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>

      <hr />

      <div className='text-right'>
        <Button disabled={disabled} type='submit' variant='primary'>
          {t('profile_settings_form.submit_text')}
        </Button>
      </div>
    </Form>
  )
}

export default ProfileSettingsForm
