import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import SummaryCard from '../../components/SummaryCard'
import { dateTime, numbers, identities } from '../../helpers'
import { useUser, useDocument, useMonth, useProgressLoader } from '../../hooks'
import { Currency, Timesheet } from '../../types'
import { firebase } from '../../services'
import { FIRESTORE } from '../../constants'

const Stats: React.FC = () => {
  const { t } = useTranslation()
  const { userProfile, uid } = useUser()
  const { selected } = useMonth()
  const { startLoader, stopLoader } = useProgressLoader()
  const timesheetDoc = useDocument<Timesheet>()

  const totalAmount = React.useMemo((): string => {
    const totalMinutes = timesheetDoc.value?.totalMinutes || 0
    const currency: Currency = userProfile?.preferences?.currency || 'PLN'
    const amountPerMinute = (userProfile?.preferences?.amount || 0) / 60
    const result = totalMinutes * amountPerMinute

    return numbers.mapAmountToCurrencyFormat(result, currency)
  }, [userProfile, timesheetDoc.value])

  const totalHumanTime = React.useMemo((): string => {
    const totalMinutes = timesheetDoc.value?.totalMinutes || 0
    const { hour, minute } = dateTime.mapMinuteToParts(totalMinutes)

    if (hour === 0 && minute > 0) {
      return t('time.minute.short', { minute })
    } else if (hour > 0 && minute > 0) {
      return t('time.hour_minute.short', { hour, minute })
    }

    return t('time.hour.short', { hour })
  }, [t, timesheetDoc.value])

  React.useEffect(() => {
    if (uid) {
      const timesheetDocID = identities.timesheetID(uid, selected.year, selected.month)

      timesheetDoc.setQuery(firebase.firestore
        .collection(FIRESTORE.TIMESHEETS)
        .withConverter(firebase.converters.timesheet)
        .doc(timesheetDocID)
      )
    }
  }, [selected, timesheetDoc, uid])

  React.useEffect(() => {
    timesheetDoc.loading ? startLoader() : stopLoader()
  }, [startLoader, stopLoader, timesheetDoc.loading])

  return (
    <Row>
      <Col sm='6'>
        <SummaryCard className='mb-3' title={t('summary.stats.total_time_text')} value={totalHumanTime} />
      </Col>
      <Col sm='6'>
        <SummaryCard className='mb-3' title={t('summary.stats.total_amount_text')} value={totalAmount} />
      </Col>
    </Row>
  )
}

export default Stats
