import classnames from 'classnames'
import { firestore } from 'firebase/app'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { usePromise } from 'react-use'
import Timer from '../../components/Timer'
import WorkTimeEditor, { SubmitHandlerData } from '../../components/WorkTimeEditor'
import { FIRESTORE } from '../../constants'
import { dateTime, identities } from '../../helpers'
import { useDocument, useLoading, useProgressLoader, useUser } from '../../hooks'
import { firebase } from '../../services'
import { WorkTimer } from '../../types'

type WorkTimeTimerProps = {
  className?: string
}

const WorkTimeTimer: React.FC<WorkTimeTimerProps> = ({
  className
}) => {
  const timerDoc = useDocument<WorkTimer>()
  const isMounted = usePromise()
  const updateTimer = useLoading<void>()
  const { uid } = useUser()
  const { startLoader, stopLoader } = useProgressLoader()
  const [stopDate, setStopDate] = React.useState<Date>()

  const loading: boolean = updateTimer.loading || timerDoc.loading
  const showEditor: boolean = !!timerDoc.value?.startDate && !!stopDate

  function handleOnStartTimer () {
    if (!uid) {
      throw new Error('Could not start timer, UID missing!')
    }

    const workTimer: WorkTimer = { startDate: dateTime.mapDateWithRoundedTime() }
    const query = firebase.firestore
      .collection(FIRESTORE.TIMERS)
      .withConverter(firebase.converters.workTimer)
      .doc(uid)

    updateTimer.setLoading(true)

    isMounted(query.set(workTimer))
      .then(() => updateTimer.setLoading(false))
      .catch(updateTimer.setError)
  }

  function handleOnStopTimer () {
    setStopDate(dateTime.mapDateWithRoundedTime())
  }

  function handleOnCancelEditor () {
    if (!uid) {
      throw new Error('Could not stop timer, UID missing!')
    }

    const query = firebase.firestore
      .collection(FIRESTORE.TIMERS)
      .withConverter(firebase.converters.workTimer)
      .doc(uid)

    updateTimer.setLoading(true)

    isMounted(query.delete())
      .then(() => updateTimer.setLoading(false))
      .catch(updateTimer.setError)
  }

  function handleOnSubmitEditor (data: SubmitHandlerData) {
    if (!uid) {
      throw new Error('Could not add work time, UID missing!')
    }

    const batch = firebase.firestore.batch()
    const incrementMinutes = firestore.FieldValue.increment(data.durationAsMinutes)
    const timesheetDocID = identities.timesheetID(uid, data.year, data.month)

    const timesheetDocRef = firebase.firestore
      .collection(FIRESTORE.TIMESHEETS)
      .withConverter(firebase.converters.timesheet)
      .doc(timesheetDocID)

    const workTimesDocRef = firebase.firestore
      .collection(`${FIRESTORE.TIMESHEETS}/${timesheetDocID}/${FIRESTORE.WORK_TIMES}`)
      .withConverter(firebase.converters.workTime)
      .doc()

    const timersDocRef = firebase.firestore
      .collection(FIRESTORE.TIMERS)
      .withConverter(firebase.converters.workTimer)
      .doc(uid)

    batch.set(timesheetDocRef, {
      totalMinutes: incrementMinutes as any
    }, { merge: true })

    batch.set(workTimesDocRef, {
      duration: dateTime.mapMinutesToDuration(data.durationAsMinutes),
      endDate: data.endDate,
      id: '',
      month: data.month,
      startDate: data.startDate,
      title: data.title,
      uid,
      year: data.year
    })

    batch.delete(timersDocRef)

    updateTimer.setLoading(true)

    isMounted(batch.commit())
      .then(() => updateTimer.setLoading(false))
      .catch(updateTimer.setError)
  }

  React.useEffect(() => {
    if (uid) {
      timerDoc.setQuery(firebase.firestore
        .collection(FIRESTORE.TIMERS)
        .withConverter(firebase.converters.workTimer)
        .doc(uid)
      )
    }
  }, [uid, timerDoc])

  React.useEffect(() => {
    if (!timerDoc.value?.startDate && stopDate) {
      setStopDate(undefined)
    }
  }, [timerDoc.value, stopDate])

  React.useEffect(() => {
    loading ? startLoader() : stopLoader()
  }, [loading, updateTimer.loading, startLoader, stopLoader])

  return (
    <div className={classnames(className)}>
      <Timer
        disabled={loading}
        startDate={timerDoc.value?.startDate}
        onStart={handleOnStartTimer}
        onStop={handleOnStopTimer}
      />

      <Modal show={showEditor} backdrop='static' keyboard={false} onHide={handleOnCancelEditor}>
        <Modal.Header>
          <Modal.Title>Dodaj czas pracy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorkTimeEditor
            disabled={loading}
            fromDate={timerDoc.value?.startDate}
            toDate={stopDate}
            onCancel={handleOnCancelEditor}
            onSubmit={handleOnSubmitEditor}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default WorkTimeTimer
