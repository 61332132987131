import { firestore } from 'firebase'
import React from 'react'
import { useLoading } from './useLoading'

export type UseCollectionHook<T, E = any> = {
  collection: T[]
  error?: E
  loading: boolean
  setQuery: (query: firestore.Query<T>) => void
}

export function useCollection<T extends firestore.DocumentData> (
  query?: firestore.Query<T>
): UseCollectionHook<T> {
  const { error, loading, value, setLoading, setError, setValue } = useLoading<T[]>()
  const [queryRef, setQueryRef] = React.useState<firestore.Query<T> | undefined>(query)

  const setQuery = React.useCallback((newQuery: firestore.Query<T>) => {
    if (!queryRef?.isEqual(newQuery)) {
      setQueryRef(newQuery)
    }
  }, [queryRef])

  React.useEffect(() => {
    if (queryRef) {
      const listener = queryRef.onSnapshot(
        snap => setValue(snap.docs.map(doc => doc.data())),
        setError
      )

      setLoading(true)

      return () => {
        listener()
        setLoading(false)
      }
    }
  }, [queryRef, setError, setLoading, setValue])

  return {
    collection: value || [],
    error,
    loading,
    setQuery
  }
}
