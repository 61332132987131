import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import DayOfMonth from '../DayOfMonth'
import { dateTime } from '../../helpers'
import './work-time-item.scss'

type WorkTimeItemProps = {
  duration: string
  endDate: Date
  startDate: Date
  title: string
  onClick?: () => void
}

const WorkTimeItem: React.FC<WorkTimeItemProps> = ({
  duration,
  endDate,
  startDate,
  title,
  onClick
}) => {
  const durationInMinutes = dateTime.mapDurationToMinutes(duration)
  const durationInHuman = dateTime.mapMinuteToHumanHour(durationInMinutes)

  return (
    <div
      className={classnames('d-flex align-items-center work-time-item', { pointer: !!onClick })}
      onClick={onClick && onClick}
    >
      <DayOfMonth className='day-of-month' date={startDate} />
      <div className='title px-1'>
        <p className='mb-0'>{title}</p>
        <p className='mb-0 text-muted small'>
          <span>{dateTime.mapDateToHumanHour(startDate)}</span>
          <span> - </span>
          <span>{dateTime.mapDateToHumanHour(endDate)}</span>
        </p>
      </div>
      <div className='duration'>{durationInHuman}</div>
      {onClick && (
        <div className='ml-2 ml-sm-3 text-muted'>
          <FontAwesomeIcon icon={faChevronRight} size='sm' />
        </div>
      )}
    </div>
  )
}

export default WorkTimeItem
