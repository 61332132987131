import { firestore } from 'firebase'
import { Timesheet } from '../../../types'

interface TimesheetDocumentData extends firestore.DocumentData {
  totalMinutes: number
}

export const timesheetConverter: firestore.FirestoreDataConverter<Timesheet> = {
  fromFirestore: (snapshot, options) => {
    return snapshot.data(options) as TimesheetDocumentData
  },
  toFirestore: timesheet => {
    return timesheet
  }
}
