import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.scss'
import RestrictedRoute from './components/RestrictedRoute'
import { ROUTES } from './constants'
import CreateProfile from './pages/CreateProfile'
import CreateWorkTime from './pages/CreateWorkTime'
import EditWorkTime from './pages/EditWorkTime'
import ProfileSettings from './pages/ProfileSettings'
import SignIn from './pages/SignIn'
import Summary from './pages/Summary'
import { LoaderContextProvider, MonthContextProvider, UserContextProvider } from './providers'

const App: React.FC = () => {
  return (
    <React.Suspense fallback={null}>
      <LoaderContextProvider>
        <UserContextProvider>
          <MonthContextProvider>
            <BrowserRouter>
              <Switch>
                <RestrictedRoute exact path={ROUTES.SUMMARY} component={Summary} />
                <RestrictedRoute path={ROUTES.CREATE_PROFILE} component={CreateProfile} />
                <RestrictedRoute path={ROUTES.PROFILE_SETTINGS} component={ProfileSettings} />
                <RestrictedRoute path={ROUTES.CREATE_WORK_TIME} component={CreateWorkTime} />
                <RestrictedRoute path={ROUTES.EDIT_WORK_TIME + '/:year/:month/:id'} component={EditWorkTime} />
                <Route path={ROUTES.SIGN_IN} component={SignIn} />
              </Switch>
            </BrowserRouter>
          </MonthContextProvider>
        </UserContextProvider>
      </LoaderContextProvider>
    </React.Suspense>
  )
}

export default App
