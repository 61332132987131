import React from 'react'
import { MonthContext, MonthContextValue } from '../providers/Month'

export function useMonth (): MonthContextValue {
  const context = React.useContext(MonthContext)

  if (!context) {
    throw new Error('Couldn\'t found MonthContext!')
  }

  return context
}
