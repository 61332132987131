import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import Heading from '../../components/Heading'
import MonthPicker from '../../components/MonthPicker'
import { ROUTES } from '../../constants'
import { useAppTitle, useMonth, useUser } from '../../hooks'
import DefaultLayout from '../../layouts/default'
import { Month } from '../../types'
import Stats from './Stats'
import WorkTimeList from './WorkTimeList'
import WorkTimeTimer from './WorkTimeTimer'

const Summary: React.FC<RouteComponentProps> = ({
  history,
  location
}) => {
  const { t } = useTranslation()
  const { hasProfile } = useUser()
  const { selected, setTodayMonth, setNextMonth, setPreviousMonth } = useMonth()

  const handleOnAddClick = () => history.push(ROUTES.CREATE_WORK_TIME)
  const handleOnWorkTimeItemClick = (year: number, month: Month, id: string) => {
    history.push(ROUTES.EDIT_WORK_TIME + `/${year}/${month}/${id}`)
  }

  useAppTitle(t('summary.page_title'))

  if (!hasProfile) {
    return <Redirect to={{ pathname: ROUTES.CREATE_PROFILE, state: { from: location } }} />
  }

  return (
    <DefaultLayout title={t('summary.page_title')}>

      <WorkTimeTimer className='mb-5 text-center' />

      <MonthPicker
        className='mb-4'
        month={selected.month}
        year={selected.year}
        onNextMonth={setNextMonth}
        onPreviousMonth={setPreviousMonth}
        onTodayMonth={setTodayMonth}
      />

      <Stats />

      <Heading
        actionTitle={t('summary.last_work_time.action_title')}
        title={t('summary.last_work_time.heading')}
        onActionClick={handleOnAddClick}
      />
      <WorkTimeList onItemClick={handleOnWorkTimeItemClick} />
    </DefaultLayout>
  )
}

export default Summary
