import React from 'react'
import { MonthContext, MonthContextValue } from './MonthContext'
import { monthProviderReducer, initialState } from './monthReducer'

const MonthContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(monthProviderReducer, initialState)

  const setNextMonth = () => dispatch('increase')
  const setPreviousMonth = () => dispatch('decrease')
  const setTodayMonth = () => dispatch('today')

  const providerValue: MonthContextValue = {
    selected: state,
    setNextMonth,
    setPreviousMonth,
    setTodayMonth
  }

  return (
    <MonthContext.Provider value={providerValue}>
      {children}
    </MonthContext.Provider>
  )
}

export default MonthContextProvider
