import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './tight-layout.scss'

const TightLayout: React.FC = ({ children }) => (
  <Container as='main' className='tight-layout'>
    <Row className='justify-content-center'>
      <Col md={8} lg={6}>{children}</Col>
    </Row>
  </Container>
)

export default TightLayout
