import React from 'react'
import { FIRESTORE } from '../../constants'
import { useProgressLoader } from '../../hooks'
import { firebase } from '../../services'
import { UserContext, UserContextValue } from './UserContext'
import { initialState, userProviderReducer } from './userReducer'

const UserContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(userProviderReducer, initialState)
  const { startLoader, stopLoader } = useProgressLoader()

  React.useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(user => {
      if (!user?.uid) {
        dispatch({ type: 'unauthorize' })
      } else {
        dispatch({ type: 'authorize', uid: user.uid })
      }
    }, error => dispatch({ type: 'error', error }))

    return () => {
      listener()
    }
  }, [])

  React.useEffect(() => {
    if (state.uid) {
      const listener = firebase.firestore
        .collection(FIRESTORE.PROFILES)
        .withConverter(firebase.converters.userProfile)
        .doc(state.uid)
        .onSnapshot(
          snap => dispatch({ type: 'user-profile', userProfile: snap.data() }),
          error => dispatch({ type: 'error', error })
        )

      return () => {
        listener()
      }
    }
  }, [state.uid])

  React.useEffect(() => {
    if (state.loading) {
      startLoader()
    } else {
      stopLoader()
    }
  }, [state.loading, startLoader, stopLoader])

  if (state.loading) {
    return null
  }

  const providerValue: UserContextValue = {
    error: state.error,
    hasProfile: !!state.userProfile?.preferences,
    loading: state.loading,
    uid: state.uid,
    userProfile: state.userProfile
  }

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
