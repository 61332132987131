import { Month } from '../types'

export function generateID (pattern = 'xxxxxxxx-xxxx-5xxx-y-xx-xxxxxxxxxxxx'): string {
  return pattern.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : ((r & 0x3) | 0x8)

    return v.toString(16)
  })
}

export function timesheetID (uid: string, year: number | string, month: Month | string): string {
  return `${year}_${month}_${uid}`
}
