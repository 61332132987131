import { firestore } from 'firebase'
import { Month, WorkTime } from '../../../types'

interface WorkTimeDocumentData extends firestore.DocumentData {
  endDate: firestore.Timestamp,
  year: number
  title: string
  duration: string
  uid: string
  month: number
  startDate: firestore.Timestamp
}

export const workTimeConverter: firestore.FirestoreDataConverter<WorkTime> = {
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options) as WorkTimeDocumentData

    return {
      duration: data.duration,
      endDate: data.endDate.toDate(),
      id: snapshot.id,
      month: data.month as Month,
      startDate: data.startDate.toDate(),
      title: data.title,
      uid: data.uid,
      year: data.year
    }
  },
  toFirestore: workTime => {
    const { id, ...data } = workTime

    return data
  }
}
