import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const initConfig: InitOptions = {
  fallbackLng: 'pl',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  }
}

i18n.use(Backend)
i18n.use(LanguageDetector)
i18n.use(initReactI18next)

i18n.init(initConfig).catch(error => console.error(error))

export default i18n
